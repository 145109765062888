import { Component, Input } from '@angular/core';
import { RichTextBlock } from '@wellro/models';
import * as Delta from 'quill-delta';

@Component({
  selector: 'wellro-rich-text-viewer',
  templateUrl: './rich-text-viewer.component.html',
  styleUrls: ['./rich-text-viewer.component.scss'],
})
export class RichTextViewerComponent {
  content: Delta;

  @Input()
  set data(value: RichTextBlock) {
    this.content = value.deltas;
  }
}
