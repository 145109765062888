import { Component, Input } from '@angular/core';

@Component({
  selector: 'wellro-pulse-indicator',
  templateUrl: './pulse-indicator.component.html',
  styleUrls: ['./pulse-indicator.component.scss'],
})
export class PulseIndicatorComponent {
  @Input()
  color = '#1e88e5';
}
