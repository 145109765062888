import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';

import { ImageCropperModule } from 'ngx-image-cropper';
import { PinchZoomModule } from 'ngx-pinch-zoom';

import { UiLoadersModule } from '@wellro/ui/loaders';

import { GoogleSignInButtonComponent } from './components/google-sign-in-button/google-sign-in-button.component';
import { FacebookSignInButtonComponent } from './components/facebook-sign-in-button/facebook-sign-in-button.component';
import { BannerComponent } from './components/banner/banner.component';
import { ConfirmationDialogBoxComponent } from './components/confirmation-dialog-box/confirmation-dialog-box.component';
import { ScrollableDirective } from './directives/scrollable.directive';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatNativeDateModule } from '@angular/material/core';

import { QuillModule } from 'ngx-quill';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { UiAnimatedComponentsModule } from '@wellro/ui/animated-components';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RichTextViewerComponent } from './components/rich-text-viewer/rich-text-viewer.component';

const nativeModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const externalModules = [
  MatAutocompleteModule,
  MatCardModule,
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatStepperModule,
  MatDialogModule,
  MatSelectModule,
  MatTooltipModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatTreeModule,
  MatMenuModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  MatExpansionModule,
  MatRippleModule,
  MatChipsModule,
  ImageCropperModule,
  UiLoadersModule,
  PinchZoomModule,
  QuillModule,
  PdfViewerModule,
  UiAnimatedComponentsModule,
];

const declarations = [
  GoogleSignInButtonComponent,
  FacebookSignInButtonComponent,
  BannerComponent,
  ConfirmationDialogBoxComponent,
  ScrollableDirective,
  RichTextEditorComponent,
  RichTextViewerComponent,
  NotFoundComponent,
];

@NgModule({
  declarations: [...declarations],
  imports: [...nativeModules, ...externalModules],
  exports: [...nativeModules, ...externalModules, ...declarations],
})
export class SharedModule {}
