<button
  type="button"
  class="fb-button"
  mat-ripple
  (click)="isLoading || disabled ? null : onclick()"
  [disabled]="disabled"
>
  <span class="fb-button__icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 366 372">
      <g inkscape:label="Layer 1" id="layer1" inkscape:groupmode="layer" />
      <g inkscape:label="Layer 2" id="layer2" inkscape:groupmode="layer">
        <path
          style="stroke-width: 15.5052"
          d="m 242.06744,62.652689 h 33.97211 V 3.4844745 c -5.861,-0.8062799 -26.0179,-2.62040039 -49.49292,-2.62040039 -48.98124,0 -82.53471,30.80903689 -82.53471,87.43437689 V 140.41177 H 89.960453 v 66.14561 h 54.051467 v 166.43387 h 66.26965 V 206.57289 h 51.86522 l 8.23332,-66.14561 H 210.26606 V 94.857195 c 0.0154,-19.118026 5.16328,-32.204506 31.80138,-32.204506 z"
          id="path2"
          fill="#ffffff"
        />
      </g>
    </svg>
  </span>
  <span class="fb-button__text">
    <wellro-button-loader
      *ngIf="isLoading"
      color="#ffffff"
    ></wellro-button-loader>
    <span *ngIf="!isLoading">{{ label }}</span>
  </span>
</button>
