import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wellro-facebook-sign-in-button',
  templateUrl: './facebook-sign-in-button.component.html',
  styleUrls: ['./facebook-sign-in-button.component.scss'],
})
export class FacebookSignInButtonComponent {
  @Output() btnClick = new EventEmitter<void>();
  @Input() isLoading: boolean;
  @Input() disabled: boolean;
  @Input() label: string;

  onclick(): void {
    this.btnClick.emit();
  }
}
